@use '../Abstracts' as *;

.map-view-details {
  @include sans-serif-base;
  position: absolute;
  top: $base-size;
  left: $base-size;
  background-color: rgba($darkless, .7);
  color: $green;
  display: flex;
  border-radius: $base-down-3;

  span {
    margin: $base-down-3 $base-down-2;
  }

  span::before {
    content: attr(data-label);
    @include sans-serif-base;
    font-size: $base-down-1;
    color: $green;
    display: inline;
  }
}
