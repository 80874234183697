@use '../Abstracts' as *;

.countdown-card {
  background-color: $dark;
  padding: $base-down-2 $base-size;
  flex-basis: 20%;
  border-radius: $base-down-2;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.countdown-head {
  @include sans-serif-base;
  display: inline;
  text-align: right;
  font-size: $base-up-1;
  font-weight: bolder;
  color: $smoke;
  margin-bottom: $base-down-3;

  user-select: none;
  cursor: default;
}

.countdown-head::before {
  content: attr(data-label);
  @include sans-serif-base;
  font-size: $base-size;
  color: $primary;
  display: inline;
}

.countdown-timer {
  display: flex;
  justify-content: flex-end;
  margin: 0;
  height: 3rem;
}

.timer-display {
  @include sans-serif-base;
  @include small-shadow;
  @include text-shadow;
  display: inline-block;
  padding: $base-down-3;
  flex-basis: 20%;
  margin: 0 $base-down-4;
  font-weight: bold;
  font-size: $base-up-2;
  background: linear-gradient($primary, darken($primary, 1.5));
  color: $snow;
  border-radius: $base-down-3;
  user-select: none;
  cursor: default;

}

.timer-display:last-child {
  margin-right: 0;
}

.timer-display::after {
  @include sans-serif-base;
  font-size: $base-down-1;
  content: attr(data-label);
  color: $snow;
  display: block;
}
