@mixin text-shadow {
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25), 0 2px 4px rgba(0, 0, 0, 0.125);
}

@mixin small-shadow {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.0625), 0 2px 4px rgba(0, 0, 0, 0.0625);
}

@mixin card-shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.125);
}

@mixin elevated-shadow {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.0625), 0 8px 12px rgba(0, 0, 0, 0.125);
}
