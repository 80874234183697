@use '../Abstracts' as *;

.map-marker {
  background-color: $primary;
  border-radius: 50%;
  margin: 0;

  position: absolute;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;

  color: $smoke;
  cursor: pointer;
  @include simple-hover-timing;
}

.map-marker::before {
  content: "";
  position: absolute;
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  transform: rotateZ(-45deg);
  border: $base-down-2 solid $primary;
  border-radius: 50% 50% 50% 0;
}

.map-marker::after {
  content: "";
  position: absolute;
  height: $base-up-8;
  width: $base-up-8;
  left: 0;
  top: $base-up-4;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: inset 0 0 $base-down-4 $base-down-3 $primary;
  transform:  perspective(800px) rotateX(75deg);

  -webkit-animation: pulse 2s infinite linear;
  -moz-animation: pulse 2s infinite linear;
  animation: pulse 2s infinite linear;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: perspective(800px) rotateX(75deg) scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: perspective(800px) rotateX(75deg) scale(2);
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    transform: perspective(800px) rotateX(75deg) scale(0);
    opacity: 1;
  }
  100% {
    transform: perspective(800px) rotateX(75deg) scale(2);
    opacity: 0;
  }
}

.map-marker:hover {
  color: $snow;
}

.map-marker-icon {
  position: absolute;
}
