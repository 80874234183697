@use '../Abstracts' as *;

.jumbotron {
  @include card-shadow;
  @include translucent-dark;
  @include simple-hover-timing;

  width: auto;
  height: 45vh;

  position: relative;
  overflow: hidden;
  border-radius: $base-down-2;
}
