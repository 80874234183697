@use '../Abstracts' as *;
@use 'button-base' as *;

@mixin plain-red-button {
  @include button-base;

  color: $smoke;
  background-color: rgba($primary, .8);
  border-radius: $base-down-2;
  border-bottom: solid $base-down-4 rgba($accent, .4);
  width: 4rem;
  height: 3.9rem;
}
