@use '../Abstracts' as *;
@use '../Base/button-base' as *;

.playlist-bar {
  background-color: $darker;
  height: 80px;
  border-radius: $base-down-2;
  margin-top: $base-size;
  //padding: $base-down-2;
  display: flex;
  justify-content: center;

  h2 {
    color: $smoke;
  }
}

.playlist-bar-auth {
  @include button-base;
  color: $smoke;
  font-size: $base-up-2;
  background-color: $steel;
  width: 100%;
  height: 100%;
  margin: auto;
  border-radius: $base-down-2;
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25), 0 2px 4px rgba(0, 0, 0, 0.125);

  svg {
    height: 2rem;
    filter: 0 1px 2px rgba(0, 0, 0, 0.0625), 0 2px 4px rgba(0, 0, 0, 0.0625);
    -webkit-filter: 0 1px 2px rgba(0, 0, 0, 0.0625), 0 2px 4px rgba(0, 0, 0, 0.0625);
  }
}

.playlist-bar-auth:hover {
  color: $snow;
  background-color: $spotify;
  font-weight: bolder;
}

.spotify-player {
  width: 100%;
  height: 100%;
  border-radius: $base-down-3;
}
