@use './Abstracts' as *;
@use './Components';
@use './Containers';
@use './ContentSections';

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5rem;
  position: relative;
  overflow-x: hidden;
}

body {
  background-color: $dark;


}
