@use '../Abstracts' as *;

.banner-section {
  background-color: $darkless;
  margin: 1rem auto;

  width: 80vw;
  padding: $base-size;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.125);
  border-radius: 1rem;
}

.top-line {
  display: flex;
  justify-content: space-between;
  padding-bottom: $base-size;
}
