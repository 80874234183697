$base-size: 1rem;

$base-up-1: 1.25rem;
$base-up-2: 1.5rem;
$base-up-3: 1.75rem;
$base-up-4: 2rem;
$base-up-5: 2.25rem;
$base-up-6: 2.5rem;
$base-up-7: 2.75rem;
$base-up-8: 3rem;
$base-up-9: 3.25rem;
$base-up-10: 3.5rem;

$base-down-1: .75rem;
$base-down-2: .5rem;
$base-down-3: .25rem;
$base-down-4: .125rem;
