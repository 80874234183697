@use '../Abstracts' as *;

.section-head {
  @include ultratitle;
  margin: auto 0;
  margin-bottom: -$base-down-1;
  color: $primary;
  text-align: left;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25), 0 2px 4px rgba(0, 0, 0, 0.125);
  user-select: none;
  -webkit-user-select: none;
  cursor: default;

  em {
    color: $smoke;
    font-style: normal;
  }
}
