@use '../Abstracts' as *;
@use '../Base/button-base' as *;

.close-view-button {
  @include button-base;
  @include simple-hover-timing;
  @include small-shadow;
  position: absolute;
  top: $base-down-2;
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  padding: 0;

  background-color: $slate;
  color: $snow;
}

.close-view-button:hover {
  color: $smoke;
  background-color: $primary;
}

.mb-wrapper .close-view-button.right-side {
  right: $base-down-2;
}

.mb-wrapper .close-view-button.left-side {
  left: 42.5%;
}
