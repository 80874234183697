@use '../Abstracts' as *;
@use '../Base/_button-base' as *;

.nav-bar {
  position: absolute;
  height: 4rem;
  top: $base-size;
  right: $base-size;
  @include small-shadow;
  @include simple-hover-timing;
}

.nav-bar-toggler {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;

  width: 4rem;

  background-color: rgba($primary, .8);
  border-top-right-radius: $base-down-2;
  border-bottom-right-radius: $base-down-2;
  border: solid $base-down-4 rgba($accent, .4);
  border-right: none;
  border-top: none;

  padding: 0;
  margin-top: 0;
  color: $smoke;

  cursor: pointer;

  @include simple-hover-timing;
}

.nav-bar .nav-bar-toggler:hover {
  color: $snow;
}

.nav-bar.expand:hover .nav-bar-toggler {
  color: $snow;
}

.nav-bar.expand .nav-bar-toggler {
  color: $smoke;
  background-color: $primary;
}

.nav-bar.collapse .nav-bar-toggler {
  border-radius: $base-down-2;
  border-left: solid $base-down-4 rgba($accent, 0);
}

.nav-overflow-wrapper {
  overflow: hidden;
  position: absolute;
  right: 4rem;

  pointer-events: none;
}

.nav-buttons {
  background-color: rgba($primary, .6);
  padding: $base-down-1;
  backdrop-filter: blur($base-up-2);

  border-bottom: solid $base-down-4 rgba($accent, .4);
  border-top-left-radius: $base-down-2;
  border-bottom-left-radius: $base-down-2;

  cursor: pointer;

  display: flex;

  margin: auto;
  @include simple-hover-timing;
}

.nav-bar.collapse .nav-buttons {
  transform: translateX(100%);
}

.nav-buttons.collapse {
  transform: translateX(-100%);

  .nav-bar-button {
    opacity: 0;
  }
}

.nav-bar:nth-of-type(2) {
  top: 6rem;

  .nav-bar-toggler {
    border-top-left-radius: $base-down-2;
    border-top-right-radius: $base-down-2;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .nav-bar-button {
    margin: $base-down-3 0;
    padding: $base-down-3 $base-down-3;
    padding-bottom: 0;
  }

  .nav-overflow-wrapper {
    right: 0;
    top: 4rem;
  }

  .nav-buttons {
    flex-direction: column;
    justify-content: space-around;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: $base-down-2;
    border-bottom-left-radius: $base-down-2;
    padding-top: $base-down-2;
    padding-bottom: $base-down-2;
  }
}

.nav-bar:nth-of-type(2).collapse {
  .nav-bar-toggler {
    border-radius: $base-down-2;
  }

  .nav-buttons {
    transform: translateY(-100%);
  }
}
