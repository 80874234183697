@use '../Abstracts' as *;

@mixin button-base {
  border: none;
  outline: none;
  font-weight: bold;
  cursor: pointer;
  @include simple-hover-timing;
}

@mixin button-base-size-up {
  @include button-base;
  font-size: $base-up-2;
}

@mixin button-base-hover {
  font-weight: bolder;
}

@mixin underlinable-button {
  position: relative;
}

@mixin button-underline {
    content: "";
    position: absolute;
    height: $base-down-4;
    top: 105%;
    left: 0;
    right: 95%;

    background-color: rgba($accent, 0);
    border-radius: $base-down-4;
    @include simple-hover-timing;
}

@mixin button-underline-hover {
  background-color: rgba($accent, 1);
  right: 2%;
}
