@use '../Abstracts' as *;

.jumbo-content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 400;
  transform: translateY(105%);

  backface-visibility: hidden;
  -webkit-backface-visiblity: hidden;

  @include simple-hover-timing;
}

.jumbo-content > :first-child {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.jumbotron > :first-child {
  z-index: 405;
  transform: translateY(0);
}
