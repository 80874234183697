@use '../Base/button-base' as *;
@use '../Abstracts' as *;

.nav-bar-button {
    @include button-base-size-up;
    @include underlinable-button;
    padding: $base-down-3 $base-up-1;
    margin: 0 $base-down-4;
    border-radius: $base-down-3;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.0625), 0 2px 4px rgba(0, 0, 0, 0.0625);

    pointer-events: all;

    background-color: $primary;
    color: $smoke;
    white-space: nowrap;
}

.nav-bar-button:hover {
  @include button-base-hover;
  color: $snow;
}

.nav-bar-button::after {
  @include button-underline;
}

.nav-bar-button:hover::after {
  @include button-underline-hover;
}

.nav-bar-button.active::after {
  @include button-underline-hover;
  background-color: $smoke;
}
