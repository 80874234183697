@use '../Abstracts' as *;

.map-info-card {
  position: absolute;
  top: $base-size;
  bottom: $base-up-3;
  width: 40%;

  display: flex;
  flex-direction: column;

  border-radius: $base-down-3;
  padding: $base-down-1;
  @include translucent-dark;
  @include small-shadow;
}

.map-info-card.right-side {
  right: $base-size;
}

.map-info-card.left-side {
  left: $base-up-3;
}

.map-info-card-head {
  color: $snow;
  text-align: left;
  margin: 0;
  margin-bottom: $base-down-2;
  user-select: none;
}

.map-info-card-subsection {
  margin: 0;
  margin-bottom: $base-down-2;
  padding: $base-down-2;
  background-color: $darkless;
  border-radius: $base-down-4;
  text-align: left;
}

.map-info-card-subsection:last-child {
  margin-bottom: 0;
  flex-grow: 1;
}

.card-subsection-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.location-info {
  @include sans-serif-base;
  display: inline;
  text-align: right;
  font-size: $base-up-1;
  font-weight: bolder;
  color: $smoke;
  user-select: none;
  margin-bottom: $base-down-3;
}

.location-info::before {
  content: attr(data-label);
  @include sans-serif-base;
  font-size: $base-size;
  color: $primary;
  display: inline;
}

p.location-info {
  text-indent: -2.5rem;
  text-align: left;
  margin-left: 2.5rem;
  margin-top: 0;
}

.location-info.note {
  color: $muted;
}

.location-info.note:nth-child(2n-1) {
  color: lighten($muted, 10);
}
