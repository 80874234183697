@use '../Abstracts' as *;
@use '../Base/plain-red-button' as *;

.home-button {
    @include plain-red-button;
    position: absolute;
    top: $base-size;
    right: -4rem;
    padding-top: $base-down-3;
}

.home-button:hover {
  color: $snow;
}

.home-button.shows {
  transform: translateX(-5rem);
}

.home-button.shows + .nav-bar {
  transform: translateX(-5rem);
}
