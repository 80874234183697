@use './colors' as *;

@mixin translucent-dark {
  background-color: rgba($dark, 0.875);
  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    background-color: rgba($dark, 0.625);
    backdrop-filter: saturate(180%) blur(16px);
    -webkit-backdrop-filter: saturate(180%) blur(16px);
  }
  @media (prefers-reduced-transparency: reduce) {
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
  }
}

@mixin translucent {
  background-color: rgba(255, 255, 255, 0.98);
  color: $text;
  box-shadow: none;
  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    background-color: rgba(255, 255, 255, 0.75);
    backdrop-filter: saturate(180%) blur(20px);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
  }
  @media (prefers-reduced-transparency: reduce) {
  backdrop-filter: none;
  -webkit-backdrop-filter: none;
  }
}
